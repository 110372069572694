
  
 .carousel-track {
    display: flex;
    transition: none; /* Sin animación por defecto */
    grid-column-gap: 65px;  grid-row-gap: 65px;
  }
  
  .carousel-track.scrolling {
    animation: scroll 20s linear infinite;
  }
  
  .carousel-logos {
    display: flex;
    grid-column-gap: 65px;  grid-row-gap: 65px;
  }
  
  

  /* Animación para el desplazamiento infinito */
  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-25%); /* Desplaza al 50% del ancho total */
    }
  }
  