@import "~@flaticon/flaticon-uicons/css/all/all";

.truncate-1-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* Número de líneas que deseas mostrar antes de truncar */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important; /* Cambiado de nowrap a normal para permitir el salto de línea */
  width: 100% !important;
}
.truncate-2-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Número de líneas que deseas mostrar antes de truncar */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important; /* Cambiado de nowrap a normal para permitir el salto de línea */
  width: 100% !important;
}
.truncate-3-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important; /* Número de líneas que deseas mostrar antes de truncar */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important; /* Cambiado de nowrap a normal para permitir el salto de línea */
  width: 100% !important;
}
.truncate-4-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important; /* Número de líneas que deseas mostrar antes de truncar */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important; /* Cambiado de nowrap a normal para permitir el salto de línea */
  width: 100% !important;
}