.carousel-track-testimonios {
  display: flex;
  transition: none; /* Sin animación por defecto */
  grid-column-gap: 25px;
  grid-row-gap: 25px;
}

.carousel-track-testimonios.scrolling-testimonios {
  animation: scroll-testimonios 90s linear infinite;
  
}

.carousel-testimonios {
  display: flex;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
}

.div-block-2-testimonios {
  flex: 0 0 auto;
  margin: 0 10px;
  
}

/* Animación para el desplazamiento infinito */
@keyframes scroll-testimonios {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-25%); /* Desplaza al 50% del ancho total */
  }
}
