.home-info-1-header {
  grid-row-gap: 120px;
  background-image: linear-gradient(#000, #040f22);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 739px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  position: relative;
}

.boton-conoce-mas {
  z-index: 1;
  grid-column-gap: 10px;
  cursor: pointer;
  background-color: #cbeeae;
  border-radius: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
}

.boton-conoce-mas-texto {
  color: #1b350a;
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
}

.home-info-1-publicidad {
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: flex;
}

.home-info-1-publicidad-texto {
  z-index: 1;
  color: #fff;
  letter-spacing: -.02em;
  width: 100%;
  max-width: 600px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
  position: relative;
}

.particles-js-canvas-el {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.difuminacion-abajo-n {
  z-index: 1;
  width: 100%;
  height: 4rem;
  position: absolute;
  inset: auto 0% 0%;
}

.menu {
  grid-row-gap: 10px;
  border: 0 solid #a7b8c440;
  border-bottom-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1820px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-decoration: none;
  display: flex;
}

.menu-2 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-decoration: none;
  display: flex;
}

.menu-info {
  grid-column-gap: 40px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  max-width: 748px;
  text-decoration: none;
  display: flex;
}

.menu-info-1 {
  z-index: 1;
  grid-column-gap: 3px;
  cursor: pointer;
  border-radius: 24px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.menu-info-1-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
}

.flechas-arriba-abajo {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 8px 6px;
  text-decoration: none;
  display: none;
}

.menu-botones {
  grid-column-gap: 30px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.boton-contactanos {
  z-index: 1;
  background-color: #cbeeae;
  border-radius: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  text-decoration: none;
  display: none;
  position: relative;
}

.boton-contactanos-texto {
  color: #1b350a;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Cairo, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.body {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.home-info-2 {
  grid-column-gap: 70px;
  background-image: linear-gradient(90deg, #000, #040f22);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 492px;
  padding: 60px 50px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.home-info-2-contenido-1 {
  grid-column-gap: 0px;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  height: 235px;
  min-height: 235px;
  max-height: 2635px;
  display: flex;
}

.home-info-2-contenido-1-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.home-info-2-contenido-2 {
  grid-row-gap: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 883px;
  text-decoration: none;
  display: flex;
}

.home-info-2-contenido-2-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.home-info-2-boton {
  grid-column-gap: 25px;
  cursor: pointer;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.home-info-2-boton-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.home-info-3-publicidad {
  grid-column-gap: 99px;
  background-image: linear-gradient(90deg, #000, #040f22);
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 80px 50px;
  text-decoration: none;
  display: flex;
}

.info-publicidad-contenido {
  grid-row-gap: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: flex;
}

.frame-30 {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: flex;
  position: absolute;
  inset: -3% 0% auto;
}

.info-publicidad-contenido-texto-1 {
  color: #fbf7f3;
  text-align: center;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
}

.frame-28 {
  grid-row-gap: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 155px;
  padding-right: 155px;
  text-decoration: none;
  display: flex;
}

.frame-27 {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.rectangle-1 {
  background-color: #73737333;
  border-radius: 10px;
  width: 100%;
  height: 2px;
}

.div-block {
  z-index: 1;
  cursor: pointer;
  position: relative;
}

.home-info-5-servicios {
  grid-row-gap: 40px;
  background-image: linear-gradient(89.85deg, #000, #040f22);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 200rem;
  padding: 140px 50px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.servicios-titulo {
  color: #fff;
  letter-spacing: -.01em;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
}

.servicios-contenido {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  height: 728px;
  transition: all .2s;
  display: flex;
}

.servicios-contenido-2 {
  grid-column-gap: 40px;
  scrollbar-width: none;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: flex;
  overflow-x: auto;
}

.servicio-tarjeta {
  cursor: pointer;
  border: 1px solid #a7b8c440;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 293px;
  max-width: 293px;
  height: 558px;
  min-height: 558px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
}

.servicio-tarjeta-imagen {
  grid-row-gap: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 196px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.iconos-servicios {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.servicio-tarjeta-contenido {
  grid-row-gap: 115px;
  background-color: #191919;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 65%;
  padding: 20px;
  transition: all .2s;
  display: flex;
}

.servicio-tarjeta-contenido-titulo {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.servicio-tarjeta-contenido-titulo-1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 200;
  line-height: 140%;
  text-decoration: none;
}

.servicio-tarjeta-contenido-titulo-2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.servicio-tarjeta-contenido-info {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.linea-divisora {
  grid-column-gap: 10px;
  background-color: #d9d9d9;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 370px;
  height: 1px;
  text-decoration: none;
  display: none;
}

.boton-ver-todos {
  grid-column-gap: 8px;
  cursor: pointer;
  background-color: #122033;
  border-radius: 40px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  text-decoration: none;
  transition: all .2s;
  display: none;
}

.boton-ver-todos-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Cairo, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.home-info-4-nosotros {
  background-color: #fbf7f3;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 200rem;
  padding: 100px 50px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.servicios-titulo-2 {
  color: #294a53;
  text-align: center;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
}

.error-a1f1c374-ba28-f9c5-8184-0574815fb66b {
  color: #294a53;
  text-align: center;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
}

.frame-94 {
  grid-column-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: flex;
  position: sticky;
  top: 13%;
  bottom: 5%;
  transform: translate(0, 25px);
}

.frame-90 {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 320px;
  text-decoration: none;
  display: flex;
}

.frame-87 {
  grid-row-gap: 14px;
  opacity: .3;
  border: 2px dotted #69b5ae;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 320px;
  height: 143px;
  display: flex;
}

.frame-81 {
  grid-row-gap: 14px;
  border: 2px solid #69b5ae;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 320px;
  height: 400px;
  padding: 20px 25px;
  text-decoration: none;
  display: flex;
}

.frame-100 {
  grid-row-gap: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.frame-82 {
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.experiencia-en-la-nube {
  color: #294a53;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  text-decoration: none;
}

.error-fde33e83-0f7d-d820-7089-405f63c220ac {
  color: #90a3a6;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 117%;
  text-decoration: none;
}

.frame-99 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.frame-78 {
  grid-row-gap: 14px;
  border: 2px solid #69b5ae;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 320px;
  padding: 20px 25px;
  text-decoration: none;
  display: flex;
}

.talento-excepcional {
  color: #294a53;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  text-decoration: none;
}

.trabajamos-en-productos-complejos-es-por-eso-que-cada-proyecto-es-personalizado {
  color: #90a3a6;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 117%;
  text-decoration: none;
}

.frame-79 {
  grid-row-gap: 14px;
  background-image: linear-gradient(90deg, #031c26, #053142);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  padding: 20px 25px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.m-s-sobre-la-historia-de-cloudih {
  color: #fff;
  letter-spacing: -.01em;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  text-decoration: none;
}

.frame-83 {
  grid-column-gap: 10px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.conoce-nuestra-historia {
  color: #294a53;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 117%;
  text-decoration: none;
}

.frame-101 {
  grid-row-gap: 14px;
  border: 2px solid #69b5ae;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 320px;
  padding: 20px 25px;
  text-decoration: none;
  display: none;
}

.liderazgo-en-dise-o {
  color: #294a53;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  text-decoration: none;
}

.error-5a5a2c81-e274-8f31-e281-bcfa259957e4 {
  color: #90a3a6;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 117%;
  text-decoration: none;
}

.frame-85 {
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.comunicaci-n-impecable {
  color: #294a53;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  text-decoration: none;
}

.error-679d74c4-b7c2-73a6-e949-8267e2f41e33 {
  color: #90a3a6;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 117%;
  text-decoration: none;
}

.frame-98 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.frame-89 {
  grid-column-gap: 10px;
  border: 1px solid #69b5ae;
  border-radius: 200px;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  height: 50px;
  text-decoration: none;
  display: flex;
  position: absolute;
  inset: 0% 0% auto 50%;
  transform: translate(-25px);
}

.frame-96 {
  grid-column-gap: 10px;
  border: 1px solid #69b5ae;
  border-radius: 200px;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  height: 50px;
  text-decoration: none;
  display: flex;
  position: absolute;
  inset: auto 0% 0% 50%;
  transform: translate(-25px);
}

.frame-97 {
  grid-column-gap: 10px;
  border: 1px solid #69b5ae;
  border-radius: 200px;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  height: 50px;
  text-decoration: none;
  display: flex;
  position: absolute;
  inset: 0%;
  transform: translate(0, 50px);
}

.frame-95 {
  grid-column-gap: 10px;
  border: 1px solid #69b5ae;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  text-decoration: none;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
  transform: translate(0, 50px);
}

.frame-30-satisfechos {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: flex;
}

.frame-90-centro {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.sect-frameworks {
  grid-row-gap: 10px;
  background-image: linear-gradient(90.24deg, #000, #040f22);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 820px;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.id-m42ajm2i038988-frame-4 {
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 350px;
  max-width: 450px;
  height: 100%;
  min-height: 300px;
  max-height: 400px;
  text-decoration: none;
  display: flex;
}

.id-m42ajm2h982468-frame-2 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.id-m42ajm2g783118-frameworks {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
  text-decoration: none;
}

.id-m42ajm2h275132-estructura-cloudih {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.id-m42ajm2i528560-frame-3 {
  grid-column-gap: 10px;
  cursor: pointer;
  background-color: #2f394c;
  border: 2px solid #4b828b;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  text-decoration: none;
  transition: all .2s;
  display: none;
}

.id-m42ajm2i645924-explorar-m-s {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.sect-testimonios {
  grid-row-gap: 63px;
  background-image: linear-gradient(90.09deg, #000, #040f22);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 80px 50px;
  text-decoration: none;
  display: flex;
}

.id-m42asw5a420374-frame-41 {
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 578px;
  text-decoration: none;
  display: flex;
}

.id-m42asw5a931306-frame-31 {
  grid-column-gap: 10px;
  background-color: #101117;
  border: 1px solid #737373;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  text-decoration: none;
  display: flex;
}

.id-m42asw4v017482-testimonios {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
}

.id-m42asw5a500713-frame-32 {
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.id-m42asw5a522165-no-conf-es-solo-en-nuestra-palabra {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.id-m42asw5a035937-la-prueba-est-en-la-plataforma {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.id-m42asw5c677491-frame-47 {
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.id-m42asw5c055075-frame-43 {
  grid-row-gap: 10px;
  background-image: linear-gradient(178.11deg, #1d2b31, #59859780);
  border: 1px solid #4b828b;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 390px;
  max-width: 390px;
  padding: 15px;
  text-decoration: none;
  display: flex;
}

.id-m42asw5b840932-mhm {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.id-m42asw5b733632-testimonio-text {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
}

.id-m42asw5c040636-frame-35 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.id-m42asw5c489649-santiago-m, .id-m42asw5c716303-director-general-de-mhm {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.sect-footer {
  grid-column-gap: 40px;
  background-color: #101010;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 80px 50px;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xj166126-footer-block {
  grid-row-gap: 17px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 272px;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xh807512 {
  color: #d9d9d9;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.id-m42ay1xj033641-divider {
  opacity: .16;
  background-color: #737373;
  width: 100%;
  height: 1px;
}

.id-m42ay1xj494077-footer-list {
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xk776884-footer-block {
  grid-row-gap: 17px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 272px;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xk816979-footer-list {
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xl049939-footer-block {
  grid-row-gap: 17px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 272px;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xl476245-footer-list {
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xl502436 {
  color: #737373;
  cursor: auto;
  border-bottom: 1px solid #0000;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
  transition: all .2s;
}

.id-m42ay1xl942667-ver-m-s {
  color: #4b828b;
  cursor: pointer;
  border-bottom: 1px solid #05050500;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
  display: none;
}

.id-m42ay1xl942667-ver-m-s:hover {
  color: #50858db3;
  border-bottom-color: #4b828b78;
}

.id-m42ay1xo871628-footer-block {
  grid-row-gap: 17px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 272px;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xo707399-footer-list {
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.id-m42ay1xo344375-social-links {
  grid-column-gap: 24px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.div-block-2 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.img {
  width: 145px;
  min-width: 145px;
  max-width: 145px;
}

.frame-101-copy, .frame-78-copy {
  grid-row-gap: 14px;
  border: 2px solid #69b5ae;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 320px;
  padding: 20px 25px;
  text-decoration: none;
  display: flex;
}

.frame-90-copy {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 320px;
  text-decoration: none;
  display: none;
}

.frame-94-copy {
  grid-column-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: none;
  position: sticky;
  top: 5%;
  bottom: 5%;
}

.img-2 {
  color: #fff;
  width: 44px;
  min-width: 44px;
  max-width: 44px;
  height: 21px;
  min-height: 21px;
  max-height: 21px;
  font-size: 44px;
  line-height: 21px;
}

.img-3 {
  z-index: 1;
  color: #cbeeae;
  cursor: pointer;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  font-size: 25px;
  line-height: 25px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.img-5 {
  z-index: 1;
  color: #cbeeae;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  font-size: 25px;
  line-height: 25px;
  display: none;
  position: relative;
}

.img-6 {
  color: #fff;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  font-size: 50px;
  line-height: 50px;
}

.img-7 {
  color: #fff;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 11px;
  min-height: 11px;
  max-height: 11px;
  font-size: 16px;
  line-height: 11px;
}

.img-10 {
  color: #fff;
  width: 13px;
  min-width: 13px;
  max-width: 13px;
  height: 6px;
  min-height: 6px;
  max-height: 6px;
  font-size: 13px;
  line-height: 6px;
}

.div-block-3 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1352px;
  display: flex;
  position: sticky;
  top: 20%;
  bottom: 25%;
}

.difuminacion-d-clientes-satisfechos {
  z-index: 1;
  background-image: linear-gradient(270deg, #030c1b 6%, #fff0);
  width: 12rem;
  height: 100%;
  position: absolute;
  inset: 0% 0 0% auto;
}

.difuminacion-i-clientes-satisfechos {
  z-index: 1;
  background-image: linear-gradient(270deg, #fff0, #000206 97%);
  width: 12rem;
  height: 100%;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.div-block-4 {
  grid-column-gap: 65px;
  grid-row-gap: 65px;
  scrollbar-width: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.img-11, .img-12, .img-13 {
  cursor: pointer;
  display: none;
}

.img-14 {
  aspect-ratio: auto;
  object-fit: contain;
  max-width: 50px;
  max-height: 50px;
}

.servicios-apartados {
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  min-width: 275px;
  max-width: 275px;
  height: 100%;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  overflow: hidden;
}

.boton-servicios {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 275px;
  text-decoration: none;
  display: flex;
}

.boton-servicio-seleccionado {
  grid-column-gap: 10px;
  cursor: pointer;
  background-color: #040f22;
  border-radius: 45px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  max-width: 275px;
  padding: 20px 25px;
  text-decoration: none;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease;
  display: flex;
}

.boton-servicio-seleccionado:hover {
  opacity: .7;
}

.servicio-1-texto {
  color: #fff;
  white-space: nowrap;
  scrollbar-width: none;
  width: max-content;
  max-width: calc(100% - 30px);
  height: 28px;
  min-height: 28px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease;
  overflow: hidden;
}

.boton-servicio {
  grid-column-gap: 10px;
  color: #fff;
  cursor: pointer;
  border-radius: 45px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  min-width: max-content;
  max-width: 275px;
  padding: 20px 25px;
  text-decoration: none;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: ease;
  display: flex;
}

.boton-servicio:hover {
  color: #b2b3b4;
}

.boton-ver-todos-2 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.ver-todos-boton-info {
  grid-row-gap: 6px;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 25px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.frame-18 {
  grid-column-gap: 25px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.ver-todos-boton-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
}

.servicios-info {
  grid-row-gap: 10px;
  background-color: #040f22;
  border: 1px solid #a7b8c440;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 353px;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 36px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.servicios-info-titulo-flecha {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.servicios-info-titulo-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.contenido {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.servicios-info-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.servicios-contenido-info {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1352px;
  height: 728px;
  min-height: 680px;
  transition: all .2s;
  display: flex;
}

.img-15 {
  color: #fff;
  cursor: pointer;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  font-size: 35px;
  line-height: 35px;
  transition: all .2s;
  display: none;
}

.img-16 {
  color: #00c278;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 20px;
}

.img-17 {
  color: #fff;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  font-size: 24px;
  line-height: 24px;
  display: none;
}

.servicio-1-texto-normal {
  white-space: nowrap;
  scrollbar-width: none;
  width: max-content;
  max-width: 100.001%;
  height: 28px;
  min-height: 28px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease;
  overflow: hidden;
}

.img-18 {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  font-size: 24px;
  line-height: 24px;
}

.div-block-5 {
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
}

.img-19 {
  color: #fff;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  font-size: 120px;
  line-height: 120px;
}

.nosotros-cloudih {
  background-color: #101010;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  display: flex;
}

.nosotros-contenido {
  grid-row-gap: 400px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.nosotros-info-general {
  grid-row-gap: 200px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.nosotros-info-1 {
  grid-row-gap: 20px;
  background-color: #fbf7f3;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 745px;
  padding: 80px 50px;
  display: flex;
}

.nosotros-info-1-titulo-2 {
  grid-row-gap: 150px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1352px;
  margin-left: 50px;
  margin-right: 50px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.nosotros-info-1-titulo-1-2 {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.nosotros-info-1-titulo-1-texto-2 {
  color: #101010;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-1-titulo-imagen-2 {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.nosotros-info-1-titulo-2-2 {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.nosotros-info-1-titulo-2-texto-2 {
  color: #101010;
  width: calc(100% - 100px);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 72px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.vectors-wrapper-2 {
  color: #000;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  height: 130px;
  min-height: 130px;
  max-height: 130px;
  font-size: 130px;
  line-height: 130px;
  text-decoration: none;
  display: flex;
}

.nosotros-metodologia {
  grid-row-gap: 80px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  text-decoration: none;
  display: flex;
}

.nosotros-metodologia-texto {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-metodologia-tarjetas {
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.metodologia-tarjeta {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 258px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.metodologia-tarjeta-info {
  grid-row-gap: 20px;
  border: 1px solid #cbeeae;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 335px;
  padding: 20px 10px;
  display: flex;
}

.metodologia-tarjeta-info-texto {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.metodologia-tarjeta-titulo {
  grid-column-gap: 10px;
  background-color: #cbeeae;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 173px;
  padding: 5px 20px;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: -16px;
  left: 42px;
}

.metodologia-tarjeta-titulo-texto {
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.linea-divisora-verde {
  grid-column-gap: 10px;
  background-color: #cbeeae40;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  max-width: 1352px;
  height: 2px;
  min-height: 2px;
  text-decoration: none;
  display: flex;
}

.nosotros-plan-accion {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  object-fit: contain;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  text-decoration: none;
  display: flex;
}

.plan-de-accion-img {
  aspect-ratio: 2;
  object-fit: contain;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 3000px;
  text-decoration: none;
  display: flex;
}

.nosotros-certificados {
  grid-row-gap: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  text-decoration: none;
  display: flex;
}

.nosotros-certificados-info {
  grid-row-gap: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.nosotros-certificados-textos {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  text-decoration: none;
  display: flex;
}

.nosotros-certificados-texto-1 {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 200;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-certificados-texto-2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.certificados {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.certificados-apartados-1 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.rectangle-3 {
  aspect-ratio: 1;
  background-color: #00205080;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
}

.nosotros-certificados-texto-3 {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
}

.linea-divisora-gris {
  grid-column-gap: 10px;
  background-color: #a7b8c440;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1352px;
  height: 2px;
  text-decoration: none;
  display: flex;
}

.img-26 {
  box-sizing: content-box;
  aspect-ratio: 1;
  object-fit: fill;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
  transition: all .2s;
}

.img-27 {
  color: #fff;
  object-fit: contain;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  font-size: 100px;
  line-height: 100px;
}

.div-block-8 {
  background-color: #000;
  width: 100%;
  height: 2px;
}

.id-m42ay1xl502436-con-hover {
  color: #737373;
  cursor: pointer;
  border-bottom: 1px solid #0000;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
  transition: all .2s;
}

.id-m42ay1xl502436-con-hover:hover {
  color: #969696;
  border-bottom: 1px solid #363636;
}

.degradado-testimonios-izq {
  background-image: linear-gradient(90deg, #000, #fff0);
  width: 250px;
  min-width: 250px;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.degradado-testimonios-der {
  background-image: linear-gradient(270deg, #040f21, #fff0);
  width: 250px;
  min-width: 250px;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.div-block-9 {
  grid-column-gap: 17px;
  grid-row-gap: 17px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.img-30 {
  color: #fff;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  font-size: 50px;
  line-height: 50px;
  display: none;
}

.fw-1 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 126px;
}

.fw-2 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  bottom: 122px;
  left: 38%;
}

.fw-3 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 50%;
  left: 16%;
}

.fw-4 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 8%;
  left: 14%;
}

.fw-5 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 31%;
  left: 3%;
}

.fw-6 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 88%;
  left: 13%;
}

.fw-7 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 83%;
  left: 68%;
}

.fw-8 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 35%;
  left: 76%;
}

.fw-9 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 18%;
  left: 83%;
}

.fw-10 {
  grid-column-gap: 10px;
  aspect-ratio: 1;
  opacity: .5;
  object-fit: scale-down;
  border: 2px solid #d9d9d966;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 68%;
  left: 89%;
}

.box-item-carucel-testimonios {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  scrollbar-width: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.nosotros-info-3 {
  grid-column-gap: 100px;
  grid-row-gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  height: auto;
  padding: 50px;
  display: flex;
  position: relative;
}

.mision {
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 500px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.mision-m {
  color: #cbeeae;
  text-align: center;
  width: 57.5469px;
  min-width: 57.5469px;
  max-width: 57.5469px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 64px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.mision-contenido {
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.mision-linea {
  grid-column-gap: 16px;
  grid-row-gap: 10px;
  background-color: #cbeeae;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: flex-start;
  width: 1px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  position: relative;
}

.mision-info {
  grid-row-gap: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 98.6094px;
  text-decoration: none;
  display: flex;
}

.mision-info-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.mision-info-texto {
  color: #fff;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 130%;
  text-decoration: none;
}

.vision {
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 500px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.vision-v {
  color: #cbeeae;
  text-align: center;
  width: 57.5469px;
  min-width: 57.5469px;
  max-width: 57.5469px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 64px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.vision-contenido {
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.vision-linea {
  grid-row-gap: 10px;
  background-color: #cbeeae;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.vision-info {
  grid-row-gap: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.vision-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.vision-titulo-texto {
  color: #fff;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 130%;
  text-decoration: none;
}

.nosotros-info-2 {
  grid-row-gap: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  padding-left: 50px;
  padding-right: 50px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-2-texto-1 {
  color: #fff;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-2-contenido {
  grid-row-gap: 38px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 986px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-2-contenido-1 {
  grid-column-gap: 30px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-right: 50px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-2-contenido-1-numero {
  grid-row-gap: 10px;
  border: 3px solid #cbeeae;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  text-decoration: none;
  display: flex;
}

.numero-1-texto {
  color: #cbeeae;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-2-contenido-1-info {
  grid-row-gap: 10px;
  border: 0 solid #cbeeae;
  border-bottom-width: 1px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-2-contenido-1-info-texto-1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-2-contenido-1-info-texto-2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-2-contenido-2 {
  grid-column-gap: 30px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-left: 50px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-2-contenido-2-info {
  grid-row-gap: 10px;
  border: 0 solid #b0b6bc;
  border-bottom-width: 1px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-2-contenido-2-numero {
  grid-row-gap: 10px;
  border: 3px solid #b0b6bc;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  text-decoration: none;
  display: flex;
}

.numero-2-texto {
  color: #b0b6bc;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-2-contenido-3 {
  grid-column-gap: 30px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-right: 50px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-2-contenido-3-numero {
  grid-row-gap: 10px;
  border: 3px solid #99e7c9;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  text-decoration: none;
  display: flex;
}

.numero-3-texto {
  color: #99e7c9;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-2-contenido-3-info {
  grid-row-gap: 10px;
  border: 0 solid #99e7c9;
  border-bottom-width: 1px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  text-decoration: none;
  display: flex;
}

.nosotros-equipo {
  grid-row-gap: 50px;
  background-color: #fbf7f3;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 100px 50px;
  text-decoration: none;
  display: flex;
}

.nosotros-equipo-titulos {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.nosotros-equipo-titulo {
  color: #101010;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-equipo-info-1-titulo {
  color: #101010;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-equipo-info {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.linea-divisora-verde-2 {
  grid-column-gap: 10px;
  background-color: #a7b8c440;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 2px;
  min-height: 2px;
  display: flex;
}

.nosotros-equipo-apartados {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 260px;
  min-height: 260px;
  text-decoration: none;
  display: flex;
}

.nosotros-equipo-tarjeta {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 250px;
  max-width: 357px;
  height: 100%;
  padding: 20px 40px;
  text-decoration: none;
  display: flex;
}

.nosotros-equipo-tarjeta-foto {
  grid-column-gap: 10px;
  background-color: #cbeeae;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.roles {
  border-radius: 200px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.untitled-design-1-1 {
  object-fit: cover;
  border-radius: 200px;
}

.nosotros-equipo-tarjeta-puesto {
  color: #101010;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.linea-divisora-vertical {
  grid-row-gap: 10px;
  background-color: #a7b8c440;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 2px;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.texto-nuestroequipo-final {
  color: #000;
  text-align: center;
  width: 100%;
  max-width: 1304px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-equipo-cel {
  grid-row-gap: 50px;
  background-color: #fbf7f3;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  padding: 100px 50px;
  text-decoration: none;
  display: none;
}

.nosotros-info-4 {
  grid-column-gap: 109px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 100px 50px;
  text-decoration: none;
  display: flex;
}

.nostros-info-4-titulo {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  height: 190px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-4-titulo-texto {
  color: #000;
  width: 305px;
  min-width: 305px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.vectors-wrapper {
  color: #000;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: 44px;
  max-width: 44px;
  height: 44px;
  min-height: 44px;
  max-height: 44px;
  font-size: 44px;
  line-height: 44px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-4-contenido {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 894px;
  text-decoration: none;
  display: flex;
}

.nosotros-info-4-contenido-1 {
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-4-contenido-2 {
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-valores {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.imagenes-valores {
  aspect-ratio: 2;
  object-fit: cover;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: none;
  height: auto;
  text-decoration: none;
  display: flex;
  overflow: visible;
}

.nosotros-info-valores-contenido {
  grid-row-gap: 50px;
  background-color: #222;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 70px 80px 80px;
  text-decoration: none;
  display: flex;
}

.component-2 {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.text {
  color: #cbeeae;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-valores-contenido-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-valores-contenido-texto-0 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-info-valores-contenido-texto-1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
}

.nosotros-header {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
}

.menu-movil {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 479px;
  text-decoration: none;
  display: none;
}

.menu-movil-titulo {
  border: 0 solid #a7b8c440;
  border-bottom-width: 1px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-3 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 22.647px;
  text-decoration: none;
  display: flex;
}

.x-1 {
  grid-column-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding-left: 1px;
  padding-right: 1px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-4 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 10.0071px;
  height: 12.0025px;
  text-decoration: none;
  display: flex;
}

.menu-movil-info {
  grid-row-gap: 8px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 351px;
  display: flex;
}

.menu-apartados {
  border: 0 solid #737373;
  border-bottom-width: 1px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  text-decoration: none;
  display: flex;
}

.boton-menu-movil-apartados-texto {
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
}

.flechas-arriba-abajo-2 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 8px 6px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-5 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 12.683px;
  height: 6.427px;
  text-decoration: none;
  display: flex;
}

.servicios-apartados-cel {
  grid-row-gap: 20px;
  background-color: #040f22;
  border: 1px solid #a7b8c440;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 25px;
  text-decoration: none;
  display: none;
}

.item-servicio {
  grid-column-gap: 10px;
  cursor: pointer;
  background-color: #040f22;
  border-radius: 45px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  max-width: 275px;
  text-decoration: none;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease;
  display: flex;
}

.item-servicio:hover {
  opacity: .7;
}

.servicio-1-texto-cel {
  color: #fff;
  white-space: nowrap;
  scrollbar-width: none;
  width: max-content;
  max-width: calc(100% - 30px);
  height: 28px;
  min-height: 28px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, Tahoma, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease;
  overflow: hidden;
}

.img-servicio-info {
  object-fit: contain;
}

.imagen-principal-2 {
  box-sizing: content-box;
  aspect-ratio: 2;
  object-fit: cover;
  object-position: 50% 90%;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1352px;
  height: 100%;
  max-height: 524px;
  display: block;
  overflow: clip;
}

.certificado-1 {
  aspect-ratio: 1;
  object-fit: cover;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
}

.certificado-2 {
  aspect-ratio: 1;
  object-fit: contain;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
}

.logo-cliente {
  aspect-ratio: 2;
  object-fit: contain;
  width: 224px;
  min-width: 224px;
  max-width: 224px;
  height: 53px;
  min-height: 53px;
  max-height: 53px;
}

@media screen and (min-width: 1280px) {
  .home-info-2-contenido-1 {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .frame-101 {
    display: flex;
  }

  .id-m42asw5c677491-frame-47 {
    min-width: 1200px;
    max-width: 1200px;
  }

  .frame-101-copy, .frame-78-copy {
    display: none;
  }

  .frame-90-copy {
    display: flex;
  }

  .difuminacion-i-clientes-satisfechos {
    background-image: linear-gradient(270deg, #fff0, #010307);
  }

  .nosotros-info-1-titulo-2-texto-2 {
    width: 780px;
    min-width: 780px;
    max-width: 780px;
  }

  .rectangle-3 {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    height: 150px;
    min-height: 150px;
    max-height: 150px;
  }

  .nosotros-certificados-texto-3 {
    font-size: 45px;
  }

  .img-26 {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    height: 150px;
  }

  .degradado-testimonios-izq {
    width: 370px;
    min-width: 370px;
  }

  .degradado-testimonios-der {
    background-image: linear-gradient(270deg, #040f21, #fff0);
    width: 370px;
    min-width: 370px;
  }

  .box-item-carucel-testimonios {
    max-width: 1200px;
  }

  .nosotros-info-3 {
    flex-flow: row;
  }

  .mision-contenido {
    height: 192.234px;
    min-height: 192.234px;
    position: relative;
  }

  .mision-linea {
    height: 100%;
    min-height: 100%;
    position: relative;
  }

  .mision-info {
    height: auto;
  }

  .vision-contenido {
    height: 192.234px;
    min-height: 192.234px;
  }

  .vision-linea {
    height: 100%;
  }

  .nosotros-info-2-texto-1, .nosotros-equipo-titulo {
    font-size: 45px;
  }

  .nosotros-equipo-info-1-titulo {
    font-size: 32px;
  }

  .nosotros-info-valores {
    flex-flow: row;
  }

  .imagenes-valores {
    max-width: 50%;
  }
}

@media screen and (min-width: 1440px) {
  .boton-contactanos {
    cursor: pointer;
    transition: all .2s;
    display: flex;
  }

  .home-info-2-contenido-2 {
    height: 235px;
    min-height: 235px;
    max-height: 235px;
  }

  .servicios-titulo {
    max-width: 1352px;
  }

  .sect-testimonios {
    position: relative;
  }

  .img-3 {
    display: none;
  }

  .difuminacion-d-clientes-satisfechos {
    background-image: linear-gradient(270deg, #030b1a, #fff0);
  }

  .difuminacion-i-clientes-satisfechos {
    background-image: linear-gradient(270deg, #fff0, #010308);
  }

  .contenido {
    height: calc(100% - 45px);
  }

  .servicio-1-texto-normal {
    width: max-content;
    min-width: max-content;
  }

  .div-block-5 {
    height: auto;
  }

  .nosotros-info-general {
    padding-top: 80px;
  }

  .degradado-testimonios-izq {
    background-image: linear-gradient(90deg, #010307, #fff0);
  }

  .degradado-testimonios-der {
    background-image: linear-gradient(270deg, #040f21, #fff0);
  }

  .fw-4 {
    left: 23%;
  }

  .fw-8 {
    left: 73%;
  }

  .fw-9 {
    top: 10%;
    left: 86%;
  }

  .fw-10 {
    top: 72%;
    left: 93%;
  }

  .nosotros-equipo-info {
    width: 100%;
    max-width: 1028.58px;
  }

  .nosotros-info-valores {
    height: 476.5px;
  }

  .imagenes-valores {
    box-sizing: border-box;
    aspect-ratio: 2;
    width: 50%;
    max-width: none;
    overflow: clip;
  }

  .nosotros-info-valores-contenido {
    height: 100%;
  }

  .nosotros-header {
    background-color: #000;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .img-servicio-info {
    aspect-ratio: 2;
    object-fit: contain;
  }

  .imagen-principal-2 {
    object-position: 50% 80%;
  }
}

@media screen and (max-width: 991px) {
  .id-m42ajm2i038988-frame-4 {
    min-width: 300px;
    max-width: 350px;
    min-height: 250px;
    max-height: 250px;
  }

  .div-block-2 {
    width: 145px;
    height: 31px;
  }

  .img {
    width: 135px;
  }

  .difuminacion-d-clientes-satisfechos {
    background-image: linear-gradient(270deg, #030b18, #fff0);
  }

  .difuminacion-i-clientes-satisfechos {
    background-image: linear-gradient(270deg, #fff0, #010409);
  }

  .div-block-4 {
    grid-column-gap: 65px;
    grid-row-gap: 65px;
  }

  .servicios-info {
    width: 100%;
  }

  .certificados {
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
  }

  .certificados-apartados-1 {
    flex-flow: column;
  }

  .nosotros-certificados-texto-3 {
    font-size: 36px;
  }

  .degradado-testimonios-der {
    background-image: linear-gradient(270deg, #040f21, #fff0);
  }

  .fw-3 {
    left: 16%;
  }

  .mision-info {
    height: auto;
  }

  .nosotros-equipo {
    padding-left: 40px;
    padding-right: 40px;
  }

  .nosotros-equipo-info {
    width: 100%;
  }

  .nosotros-equipo-tarjeta {
    min-width: 200px;
  }

  .nosotros-equipo-tarjeta-foto {
    width: 130px;
    min-width: 130px;
    height: 130px;
    min-height: 130px;
  }

  .untitled-design-1-1 {
    width: 115px;
    min-width: 115px;
    max-width: 115px;
    height: 115px;
    min-height: 115px;
    max-height: 115px;
  }

  .nosotros-equipo-cel {
    padding-left: 40px;
    padding-right: 40px;
  }

  .nosotros-header {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .home-info-1-header {
    height: auto;
    padding-bottom: 120px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-info-1-publicidad-texto {
    font-size: 36px;
  }

  .menu-info, .boton-contactanos {
    display: none;
  }

  .home-info-2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-info-2-contenido-1-texto {
    font-size: 20px;
  }

  .home-info-2-contenido-2-texto {
    font-size: 24px;
  }

  .home-info-3-publicidad {
    padding-left: 20px;
    padding-right: 20px;
  }

  .frame-30 {
    position: relative;
  }

  .info-publicidad-contenido-texto-1 {
    font-size: 24px;
  }

  .frame-28 {
    padding-left: 0;
    padding-right: 0;
  }

  .frame-27 {
    scrollbar-width: none;
    flex-flow: row;
    overflow-y: auto;
  }

  .home-info-5-servicios {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .servicios-titulo {
    font-size: 24px;
  }

  .home-info-4-nosotros {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .servicios-titulo-2 {
    font-size: 24px;
  }

  .error-a1f1c374-ba28-f9c5-8184-0574815fb66b {
    font-size: 20px;
  }

  .frame-94 {
    display: none;
  }

  .frame-90 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .frame-81 {
    width: 320px;
    max-width: 320px;
    height: auto;
    min-height: 400px;
  }

  .frame-99 {
    grid-column-gap: 28px;
    grid-row-gap: 10px;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }

  .frame-79 {
    max-width: 320px;
  }

  .id-m42ajm2i038988-frame-4 {
    min-width: 250px;
    max-width: 300px;
    min-height: 150px;
    max-height: 200px;
  }

  .id-m42ajm2g783118-frameworks {
    font-size: 36px;
  }

  .sect-testimonios {
    padding-left: 20px;
    padding-right: 20px;
  }

  .id-m42asw5a500713-frame-32 {
    justify-content: flex-start;
    align-items: center;
  }

  .id-m42asw5a522165-no-conf-es-solo-en-nuestra-palabra, .id-m42asw5a035937-la-prueba-est-en-la-plataforma {
    font-size: 24px;
  }

  .id-m42asw5c677491-frame-47 {
    flex-flow: column;
  }

  .sect-footer {
    grid-row-gap: 40px;
    flex-flow: wrap;
    padding-left: 20px;
    padding-right: 20px;
  }

  .id-m42ay1xj166126-footer-block {
    max-width: none;
    transition: all .2s;
  }

  .id-m42ay1xh807512 {
    font-size: 14px;
  }

  .id-m42ay1xk776884-footer-block, .id-m42ay1xl049939-footer-block {
    max-width: none;
    transition: all .2s;
  }

  .id-m42ay1xl502436, .id-m42ay1xl942667-ver-m-s {
    font-size: 12px;
  }

  .id-m42ay1xo871628-footer-block {
    max-width: none;
    transition: all .2s;
  }

  .div-block-2 {
    min-width: 145px;
    max-width: 145px;
  }

  .frame-101-copy {
    width: 320px;
    max-width: 320px;
  }

  .frame-94-copy {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-flow: column;
    display: flex;
    position: relative;
  }

  .img-3 {
    display: flex;
  }

  .img-5 {
    cursor: pointer;
    display: flex;
  }

  .div-block-3 {
    position: relative;
  }

  .difuminacion-d-clientes-satisfechos {
    background-image: linear-gradient(270deg, #040e20, #fff0);
  }

  .difuminacion-i-clientes-satisfechos {
    background-image: linear-gradient(270deg, #fff0, #000);
  }

  .img-14 {
    max-width: 30px;
    max-height: 30px;
    font-size: 30px;
    line-height: 30px;
  }

  .servicios-apartados {
    height: 68px;
    min-height: 68px;
    display: none;
    position: relative;
    overflow: hidden;
  }

  .servicios-info {
    background-color: #040f2200;
    border-color: #a7b8c400;
    border-radius: 0;
    height: auto;
    min-height: auto;
  }

  .contenido {
    height: auto;
  }

  .servicios-contenido-info {
    flex-flow: column;
    height: auto;
    min-height: auto;
    padding-top: 68px;
    position: relative;
  }

  .img-15 {
    display: none;
  }

  .img-16 {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    font-size: 16px;
    line-height: 16px;
  }

  .img-17 {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    font-size: 20px;
    line-height: 20px;
    display: inline-flex;
  }

  .servicio-1-texto-normal {
    max-width: calc(100% - 54px);
  }

  .div-block-5 {
    height: auto;
  }

  .nosotros-cloudih {
    position: relative;
  }

  .nosotros-contenido {
    grid-row-gap: 150px;
  }

  .nosotros-info-general {
    grid-row-gap: 100px;
  }

  .nosotros-info-1 {
    height: 595px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .nosotros-info-1-titulo-2 {
    grid-row-gap: 50px;
  }

  .nosotros-info-1-titulo-2-texto-2 {
    font-size: 48px;
  }

  .vectors-wrapper-2 {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    font-size: 80px;
    line-height: 80px;
  }

  .nosotros-metodologia {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nosotros-certificados {
    padding-left: 40px;
    padding-right: 40px;
  }

  .nosotros-certificados-textos {
    justify-content: center;
    align-items: center;
  }

  .nosotros-certificados-texto-1, .nosotros-certificados-texto-2, .nosotros-certificados-texto-3 {
    font-size: 24px;
  }

  .id-m42ay1xl502436-con-hover {
    font-size: 12px;
  }

  .degradado-testimonios-izq, .degradado-testimonios-der {
    width: 150px;
    min-width: 150px;
  }

  .div-block-10 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .img-30 {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    font-size: 24px;
    line-height: 24px;
    display: none;
  }

  .fw-1 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    top: 25%;
  }

  .fw-2 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    bottom: 18%;
  }

  .fw-3 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    left: 8%;
  }

  .fw-4, .fw-5, .fw-6, .fw-7 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
  }

  .fw-8 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    left: 79%;
  }

  .fw-9 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    top: 8%;
  }

  .fw-10 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    top: 64%;
    left: 84%;
  }

  .mision {
    min-width: auto;
  }

  .mision-m {
    font-size: 48px;
  }

  .mision-info-titulo {
    font-size: 24px;
  }

  .mision-info-texto {
    font-size: 16px;
  }

  .vision {
    min-width: auto;
  }

  .vision-v {
    font-size: 48px;
  }

  .vision-titulo {
    font-size: 24px;
  }

  .vision-titulo-texto {
    font-size: 16px;
  }

  .nosotros-info-2-texto-1 {
    font-size: 24px;
  }

  .nosotros-info-2-contenido-1 {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .nosotros-info-2-contenido-1-numero {
    width: 70px;
    min-width: 70px;
    height: 70px;
    min-height: 7px;
  }

  .nosotros-info-2-contenido-1-info-texto-1, .nosotros-info-2-contenido-1-info-texto-2 {
    font-size: 16px;
  }

  .nosotros-info-2-contenido-2 {
    flex-flow: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .nosotros-info-2-contenido-2-numero {
    width: 70px;
    min-width: 70px;
    height: 70px;
    min-height: 70px;
  }

  .nosotros-info-2-contenido-3 {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .nosotros-info-2-contenido-3-numero {
    width: 70px;
    min-width: 70px;
    height: 70px;
    min-height: 70px;
  }

  .nosotros-equipo {
    display: none;
  }

  .nosotros-equipo-titulo {
    font-size: 24px;
  }

  .nosotros-equipo-info-1-titulo {
    font-size: 16px;
  }

  .nosotros-equipo-tarjeta {
    justify-content: center;
    align-items: center;
  }

  .nosotros-equipo-tarjeta-foto {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
  }

  .untitled-design-1-1 {
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    height: 90px;
    min-height: 90px;
    max-height: 90px;
  }

  .nosotros-equipo-tarjeta-puesto {
    font-size: 14px;
  }

  .texto-nuestroequipo-final {
    font-size: 18px;
  }

  .nosotros-equipo-cel {
    display: flex;
  }

  .nosotros-info-4 {
    grid-row-gap: 50px;
    flex-flow: column;
  }

  .nostros-info-4-titulo {
    width: 100%;
    height: auto;
  }

  .nosotros-info-4-titulo-texto {
    width: 305px;
    font-size: 30px;
  }

  .nosotros-info-4-contenido-1, .nosotros-info-4-contenido-2 {
    font-size: 20px;
  }

  .nosotros-info-valores-contenido {
    padding-left: 50px;
    padding-right: 50px;
  }

  .nosotros-info-valores-contenido-texto, .nosotros-info-valores-contenido-texto-0, .nosotros-info-valores-contenido-texto-1 {
    font-size: 24px;
  }

  .nosotros-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .menu-movil {
    z-index: 2;
    min-width: 100%;
    max-width: none;
    height: 100%;
    min-height: 100%;
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
    display: flex;
    position: fixed;
    top: 0;
    transform: translate(-100%);
  }

  .menu-movil-titulo {
    z-index: 2;
    background-color: #000;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
  }

  .x-1 {
    cursor: pointer;
  }

  .vectors-wrapper-4 {
    color: #fff;
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    min-height: 12px;
    max-height: 12px;
    font-size: 12px;
    line-height: 12px;
  }

  .menu-movil-info {
    height: 100%;
  }

  .menu-apartados {
    cursor: pointer;
  }

  .flechas-arriba-abajo-2 {
    display: none;
  }

  .home {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    position: relative;
    overflow: visible;
  }

  .servicios-apartados-cel {
    z-index: 2;
    border-radius: 45px;
    max-width: 100%;
    height: 68px;
    min-height: 68px;
    transition: all .2s;
    display: flex;
    position: absolute;
    top: 0;
    overflow: hidden;
  }

  .item-servicio {
    max-width: 100%;
  }

  .servicio-1-texto-cel {
    max-width: calc(100% - 60px);
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) {
  .home-info-1-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-info-1-publicidad-texto {
    font-size: 32px;
  }

  .menu-botones {
    grid-column-gap: 10px;
  }

  .home-info-2 {
    grid-row-gap: 30px;
    flex-flow: column;
    padding: 40px 15px;
  }

  .home-info-2-contenido-1 {
    height: auto;
    min-height: auto;
    max-height: none;
  }

  .home-info-2-contenido-1-texto {
    font-size: 16px;
  }

  .home-info-2-contenido-2-texto {
    font-size: 20px;
  }

  .home-info-2-boton {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .home-info-3-publicidad {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info-publicidad-contenido-texto-1 {
    font-size: 20px;
  }

  .home-info-5-servicios {
    padding: 60px 15px;
  }

  .servicios-titulo {
    font-size: 20px;
  }

  .home-info-4-nosotros {
    padding-left: 15px;
    padding-right: 15px;
  }

  .servicios-titulo-2 {
    font-size: 20px;
  }

  .error-a1f1c374-ba28-f9c5-8184-0574815fb66b {
    font-size: 16px;
  }

  .sect-testimonios {
    padding-left: 15px;
    padding-right: 15px;
  }

  .id-m42asw5a420374-frame-41, .id-m42asw5a500713-frame-32 {
    justify-content: flex-start;
    align-items: center;
  }

  .id-m42asw5a522165-no-conf-es-solo-en-nuestra-palabra, .id-m42asw5a035937-la-prueba-est-en-la-plataforma {
    font-size: 20px;
  }

  .id-m42asw5c055075-frame-43 {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .sect-footer {
    grid-column-gap: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .id-m42ay1xl942667-ver-m-s {
    font-size: 12px;
  }

  .img-2 {
    display: none;
  }

  .img-3, .img-5 {
    width: 25px;
    min-width: 25px;
    max-width: 25px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
    font-size: 25px;
    line-height: 25px;
  }

  .img-14 {
    max-width: 20px;
    max-height: 20px;
    font-size: 20px;
    line-height: 20px;
  }

  .nosotros-contenido {
    grid-row-gap: 100px;
  }

  .nosotros-info-1 {
    height: 500px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nosotros-info-1-titulo-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .nosotros-info-1-titulo-2-texto-2 {
    font-size: 32px;
  }

  .vectors-wrapper-2 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    font-size: 50px;
    line-height: 50px;
  }

  .nosotros-metodologia, .nosotros-certificados {
    padding-left: 10px;
    padding-right: 10px;
  }

  .nosotros-certificados-texto-1, .nosotros-certificados-texto-2, .nosotros-certificados-texto-3 {
    font-size: 24px;
  }

  .degradado-testimonios-izq, .degradado-testimonios-der {
    width: 100px;
    min-width: 100px;
  }

  .img-30 {
    display: none;
  }

  .fw-1 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    top: 32%;
  }

  .fw-2 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    bottom: 21%;
  }

  .fw-3 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    top: 64%;
    left: 1%;
  }

  .fw-4 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    top: 3%;
    left: 4%;
  }

  .fw-5 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    top: 19%;
    left: 13%;
  }

  .fw-6 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
  }

  .fw-7 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    left: 64%;
  }

  .fw-8 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    top: 24%;
    left: 73%;
  }

  .fw-9 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    left: 73%;
  }

  .fw-10 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    left: 80%;
  }

  .nosotros-info-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mision-info {
    height: auto;
  }

  .nosotros-info-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .texto-nuestroequipo-final {
    font-size: 1px;
  }

  .nosotros-equipo-cel {
    padding-left: 10px;
    padding-right: 10px;
  }

  .nosotros-info-4 {
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
  }

  .nostros-info-4-titulo {
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }

  .nosotros-info-4-titulo-texto {
    width: auto;
    min-width: auto;
    font-size: 24px;
  }

  .vectors-wrapper {
    color: #000;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    font-size: 50px;
    line-height: 50px;
    transform: rotate(90deg);
  }

  .nosotros-info-4-contenido-1, .nosotros-info-4-contenido-2 {
    font-size: 16px;
  }

  .nosotros-info-valores-contenido {
    padding-left: 20px;
    padding-right: 20px;
  }

  .text, .nosotros-info-valores-contenido-texto, .nosotros-info-valores-contenido-texto-0, .nosotros-info-valores-contenido-texto-1 {
    font-size: 20px;
  }

  .home {
    flex-flow: column;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    position: relative;
  }
}


@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.ttf') format('truetype'), url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BoldItalic.ttf') format('truetype'), url('../fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}